export default {
  badge: 'insigne',
  close: 'Sluiten',
  dataIterator: {
    noResultsText: 'Geen overeenkomende resultaten gevonden',
    loadingText: 'Items aan het laden...',
  },
  dataTable: {
    itemsPerPageText: 'Rijen per pagina:',
    ariaLabel: {
      sortDescending: 'Aflopend gesorteerd.',
      sortAscending: 'Oplopend gesorteerd.',
      sortNone: 'Niet gesorterrd.',
      activateNone: 'Activeer om de sortering te verwijderen.',
      activateDescending: 'Activeer om aflopend te sorteren.',
      activateAscending: 'Activeer om oplopend te sorteren.',
    },
    sortBy: 'Sorteer volgens',
  },
  dataFooter: {
    itemsPerPageText: 'Aantal per pagina:',
    itemsPerPageAll: 'Alles',
    nextPage: 'Volgende pagina',
    prevPage: 'Vorige pagina',
    firstPage: 'Eerste pagina',
    lastPage: 'Laatste pagina',
    pageText: '{0}-{1} van {2}',
  },
  datePicker: {
    itemsSelected: '{0} geselecteerd',
    nextMonthAriaLabel: 'Volgende maand',
    nextYearAriaLabel: 'Volgend jaar',
    prevMonthAriaLabel: 'Vorige maand',
    prevYearAriaLabel: 'Vorig jaar',
  },
  noDataText: 'Geen gegevens beschikbaar',
  carousel: {
    prev: 'Vorig beeld',
    next: 'Volgend beeld',
    ariaLabel: {
      delimiter: 'Carousel slide {0} of {1}',
    },
  },
  calendar: {
    moreEvents: '{0} meer',
  },
  fileInput: {
    counter: '{0} bestanden',
    counterSize: '{0} bestanden ({1} in totaal)',
  },
  timePicker: {
    am: 'AM',
    pm: 'PM',
  },
  pagination: {
    ariaLabel: {
      wrapper: 'Paginanavigatie',
      next: 'Volgende pagina',
      previous: 'Vorige pagina',
      page: 'Ga naar pagina {0}',
      currentPage: 'Huidige pagina, pagina {0}',
    },
  },
}
